import React, {useState, useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import "./index.css";

const useStyles = makeStyles(theme => ({
  pinInput: {
    padding: theme.spacing(1, 1),
  },
}));

function App() {

  const [nyala, setNyala] = useState("0");

  const [pinTrue, setPintrue] = useState(false);

  const [pinState, setPinState] = useState([]);


  const pin = "050898";

  useEffect(()=>{
    const update = () => {
      fetch("http://test.wpli.co.id/get.php")
        .then(res => res.text())
        .then(string => setNyala(string));
        setTimeout(update, 1000);
    }
    update()
  },[]);
  
  const handleChange = () => {
    let formdata = new FormData();
    
    let d = nyala==="0"?1:0;
    
    formdata.append("value", d);
    
    fetch('http://test.wpli.co.id/update.php', {
      method: 'POST',
      body: formdata
    })
    .catch(err=>console.error(err));
  }

  const handleTruePin = () => {
    setPinState([]);
    setPintrue(true);
    setTimeout(resetSession, 10000);
  }

  const resetSession = () => {
    setPintrue(false);
  }

  const submitPin = () => {
    let temp= "";

    pinState.forEach((val)=>{
      
      temp = temp + val;
    });
    if(temp===pin){
      handleTruePin();
    }else{
      setTimeout(handleFalsePin, 100);
    }
  }

  const handleFalsePin = () => {
    setPinState([]);
    alert("Wrong pin!");
  }


  const handlePinButton = (num) => {
    pinState.push(num);
    setPinState([...pinState]);
    if(pinState.length===6){
      submitPin();
    }
  }

  const handleResetPin = () => {
    setPinState([]);
  }


  const classes = useStyles();

  
  const pinTrueComponent = (
    <div className="App">
      <Switch
          checked={nyala==="1"}
          onChange={handleChange}
          color="primary"
          size="medium"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    </div>
  );
    
  const pinFalseComponent = (
    <div className="App">
      
      <div style={{
          position: 'absolute', left: '50%', top: '30%',
          transform: 'translate(-50%, -50%)'
      }}>
        
        <table>
          <tbody>
            <tr>
              <td>
                <Paper className={classes.pinInput}>{pinState[0]==null?'_':pinState[0]}</Paper>
              </td>
              <td>
                <Paper className={classes.pinInput}>{pinState[1]==null?'_':pinState[1]}</Paper>
              </td>
              <td>
                <Paper className={classes.pinInput}>{pinState[2]==null?'_':pinState[2]}</Paper>
              </td>
              <td>
                <Paper className={classes.pinInput}>{pinState[3]==null?'_':pinState[3]}</Paper>
              </td>
              <td>
                <Paper className={classes.pinInput}>{pinState[4]==null?'_':pinState[4]}</Paper>
              </td>
              <td>
              <Paper className={classes.pinInput}>{pinState[5]==null?'_':pinState[5]}</Paper>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
    }}>
        <table>
          <tbody>
            <tr>
              <td>
                <Button onClick={handlePinButton.bind(this,1)} variant="contained" color="primary">1</Button>
              </td>
              <td>
                <Button onClick={handlePinButton.bind(this,2)} variant="contained" color="primary">2</Button>
              </td>
              <td>
                <Button onClick={handlePinButton.bind(this,3)} variant="contained" color="primary">3</Button>
              </td>
            </tr>
            <tr>
              <td>
                <Button onClick={handlePinButton.bind(this,4)} variant="contained" color="primary">4</Button>
              </td>
              <td>
                <Button onClick={handlePinButton.bind(this,5)} variant="contained" color="primary">5</Button>
              </td>
              <td>
                <Button onClick={handlePinButton.bind(this,6)} variant="contained" color="primary">6</Button>
              </td>
            </tr>
            <tr>
              <td>
                <Button onClick={handlePinButton.bind(this,7)} variant="contained" color="primary">7</Button>
              </td>
              <td>
                <Button onClick={handlePinButton.bind(this,8)} variant="contained" color="primary">8</Button>
              </td>
              <td>
                <Button onClick={handlePinButton.bind(this,9)} variant="contained" color="primary">9</Button>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <Button onClick={handlePinButton.bind(this,0)} variant="contained" color="primary">0</Button>
              </td>
              <td>
              <Button onClick={handleResetPin} variant="contained" color="secondary">C</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  return pinTrue?pinTrueComponent:pinFalseComponent;
}

export default App;